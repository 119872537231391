import { ref, reactive } from 'vue';
import ConfirmationModal from './ConfirmationModal';

/**
 * Confirmation plugin.
 * Can get called by this.$confirmation.showConfirmation() and resolves a Promise if it's confirmed.
 */
const plugin = {
  install(app) {
    const confirmation = ref(null);

    function showConfirmation(payload) {
      return new Promise((resolve, reject) => {
        this.confirmation = { resolve, reject, payload };
      })
      .catch(() => Promise.reject(new Error('Confirmation cancelled'))) // Makes sure we don't get a console error.
      .finally(() => {
        this.confirmation = null;
      });
    }

    app.component('confirmation-modal', ConfirmationModal);
    app.config.globalProperties.$confirmation = reactive({
      showConfirmation,
      confirmation
    });
  }
};

export default plugin;
