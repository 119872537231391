<template>
  <div class="scroll-slider" ref="slider">
    <ul>
      <li v-for="(slide, index) in items"
          :ref="'slide_' + slide.name"
          :class="slide.name === activeItem ? 'active' : ''"
          :key="index">
        <div v-html="slide.content" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "scroll-slider",
  mounted() {
    this.scrollToActiveItem();
  },
  props: {
    items: {
      type: [Array, String],
      default: function () {
        return []
      }
    },
    activeItem: {
      type: [String],
      default: function () {
        return ""
      }
    },
  },
  watch: {
    activeItem() {
      this.scrollToActiveItem();
    }
  },
  methods: {
    scrollToActiveItem: function () {
      if (this.$refs['slide_' + this.activeItem]) {
        this.$refs['slide_' + this.activeItem][0].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../assets/css/utilities/_mixins.scss";

.scroll-slider {
  @include hide-scrollbar();
  ul {
    display: flex;
  }
}

</style>
