import { defineStore } from 'pinia'

// the first argument is a unique id of the store across your application
export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      activeStoreUrl: "",
      currentSelectedConfigOptions: [],
      /* WIP: This attribute is used for configurable products.
      availableConfigOptions: [],
       */
      searchAutosuggestResponse: null,
      searchHits: [],
      searchSuggestions: [],
      breakpointMd: 768,
      isDesktopView: window.innerWidth >= 768,
      detectedOS: navigator.userAgent
    }
  },
  getters: {
    // Attention - this is like computed property!
    //isLoggedIn: (state) => !!state.user,
  },
  actions: {
    addSelectedConfigOption(key, value) {
      this.currentSelectedConfigOptions[key] = value;
    },
    setActiveStoreUrl(url) {
      this.activeStoreUrl = url;
      window.location.href = url;
    },
    /* Getters may cause problems, because if you need it inside a computed property for example, the getter is
    received to late sometimes */
    getActiveStoreUrl() {
      return this.activeStoreUrl;
    },
    setSearchHits(hits) {
      this.searchHits = hits;
    },
    setSearchSuggestions(suggestions) {
      this.searchSuggestions = suggestions;
    },
    async getSearchAutosuggest(endpoint, searchQuery) {
      try {
        const response = await fetch( endpoint + searchQuery);
        if (!response.ok) {
          throw new Error(`Error! status: ${response.status}`);
        }
        const result = await response.json();
        this.searchHits = result.hits;
        this.searchSuggestions = result.suggestions;
        return result;
      } catch (err) {
        this.searchAutosuggestResponse = err.json();
        throw err;
      }
    },
    setIsDesktopView(){
      this.isDesktopView = window.innerWidth >= this.breakpointMd;
    },
    /* "Alternative-Configurable-Options" HINT. See explanation in ProductController.
    async getOptionsOfSelectedConfig(endpoint, selectedOptionName, selectedOptions, childSkus, optionsToGet) {
      let requestBody = {
        "selectedOptionName": selectedOptionName,
        "selectedOptions": selectedOptions,
        "childSkus": childSkus,
        "optionsToGet": optionsToGet
      };
      console.log("requestBody::::: ", requestBody);
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify(requestBody)
        });
        if (!response.ok) {
          throw new Error(`Error! status: ${response.status}`);
        }
        const result = await response.json();
        console.log("JOJOJOJOJOJOJO: ", result);
        this.availableConfigOptions = result;
        //this.searchSuggestions = result.suggestions;
        return result;
      } catch (err) {
        //this.searchAutosuggestResponse = err.json();
        console.log("Nooo: ", err);
        throw err;
      }
    },
    */
  },
})
