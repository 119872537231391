<template>
  <div class="store-config-switcher" :class="changeFieldStyling()">
    <base-select
        :label="label"
        :values="configList"
        :name="name"
        @valueChanged="changeValue"
    />
<!--        :selected="globalStore.getActiveStoreUrl()"-->
  </div>
</template>

<script>
import { useGlobalStore } from '@/stores/global'
import baseSelect from '@/components/molecules/forms/base-select/BaseSelect';

export default  {
  name: 'store-config-switcher',
  components: {
    baseSelect,
  },
  props: {
    configList: {
      type: [Array, String, Object],
      default: function () {
        return [];
      }
    },
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  },
  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore,
    }
  },
  methods: {
    changeValue(payload) {
      this.globalStore.setActiveStoreUrl(payload.value);
    },
    changeFieldStyling() {
      if (this.globalStore.detectedOS.indexOf("Mac") !== -1) {
        return 'user-agent-' + 'mac-os';
      }
      return '';
    }
  }
};
</script>
