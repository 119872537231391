<template>
  <form role="search" class="search-form" :action="submitUrl">
    <div class="search_field_wrapper">
      <label :for="paramName" class="input-label">{{ label }}</label>
      <input :name="paramName" type="text"
             :placeholder="placeholder"
             v-model="searchTerm"
             @click="hideAutosuggest = false"
             @input="triggerSearchAutosuggest"
             @change="triggerSearchAutosuggest" />
      <Button variant="icon-only" type="submit" :label="label">
        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 8c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6-6-2.691-6-6m15.707 8.293-3.395-3.396A7.952 7.952 0 0 0 16 8c0-4.411-3.589-8-8-8S0 3.589 0 8s3.589 8 8 8a7.952 7.952 0 0 0 4.897-1.688l3.396 3.395a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414" fill="currentColor" fill-rule="evenodd"/>
        </svg>
      </Button>
    </div>
    <div class="search-autosuggest"
         :class="globalStore.searchSuggestions.length > 0 && globalStore.searchHits.length > 0 ? 'grid-cols-2' : ''"
         @mouseleave="hideAutosuggest = true"
         v-show="!hideAutosuggest"
         v-if="globalStore.searchSuggestions.length > 0 || globalStore.searchHits.length > 0">
      <div class="search-term-suggestions" v-if="globalStore.searchSuggestions.length > 0">
        <strong class="section_title">{{ headlineSuggestions }}</strong>
        <ul>
          <li v-for="(suggestion) in globalStore.searchSuggestions" :key="suggestion.text">
            <a
                href="#"
                :title="suggestion"
                @click.prevent="searchSuggesteTerm(suggestion)"
            >
              <span>{{suggestion}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="product-suggestions" v-if="globalStore.searchHits.length > 0">
        <strong class="section_title">{{ headlineHits }}</strong>
        <ul class="product_list_small limited_height">
          <li v-for="(hit) in globalStore.searchHits" :key="hit.sku">
            <div class="product_tile_small">
              <div class="img_wrapper">
                <a v-bind:href="hit.url_path" :title="hit.name">
                  <img :alt="hit.name" v-bind:src="hit.image" />
                </a>
              </div>
              <div class="content_wrapper">
                <strong class="product_title">
                  <a v-bind:href="hit.url_path" :title="hit.name">
                    {{ hit.name }}
                  </a>
                </strong>
                <p class="subtitle">{{ labelSku }} {{ hit.sku }}</p>
              </div>
              <Button label="Details" variant="icon-only primary" :is-link="true" :href="hit.url_path">
                <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8h11.865l-3.633 4.36a1 1 0 1 0 1.537 1.28l5-6c.039-.047.058-.102.087-.154.024-.042.053-.078.071-.124a.985.985 0 0 0 .072-.358L16 7l-.001-.004a.985.985 0 0 0-.072-.358c-.018-.046-.047-.082-.071-.124-.029-.052-.048-.107-.087-.154l-5-6A1.002 1.002 0 0 0 9.36.232a1 1 0 0 0-.128 1.408L12.865 6H1a1 1 0 0 0 0 2" fill="currentColor" fill-rule="evenodd"/>
                </svg>
              </Button>
            </div>
          </li>
        </ul>
      </div>
      <Button label="Close"
              class="btn_close"
              variant="icon-only"
              @click="hideAutosuggest = true">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="m8.943 8 2.862-2.862a.666.666 0 1 0-.943-.943L8 7.057 5.138 4.195a.666.666 0 1 0-.943.943L7.057 8l-2.862 2.862a.666.666 0 1 0 .943.943L8 8.943l2.862 2.862a.665.665 0 0 0 .943 0 .666.666 0 0 0 0-.943L8.943 8z" fill="currentColor" fill-rule="evenodd"/>
        </svg>
      </Button>
    </div>
  </form>
</template>

<script>
import { useGlobalStore } from '@/stores/global'
import Button from "@/components/molecules/forms/button/Button";

export default {
  name: "search-field-autosuggest",
  components: {Button},
  data () {
    return {
      searchTerm: '',
      hideAutosuggest: false
    }
  },
  props: {
    submitUrl: {
      type: [String],
      default: "search",
    },
    autosuggestEndpoint: {
      type: [String, Object],
      default: null,
    },
    label: {
      type: [String],
      default: "Browse store",
    },
    paramName: {
      type: [String],
      default: "searchInput",
    },
    placeholder: {
      type: [String],
      default: "Search...",
    },
    headlineHits: {
      type: [String],
      default: "Products",
    },
    headlineSuggestions: {
      type: [String],
      default: "Did you mean...?",
    },
    labelSku: {
      type: [String],
      default: "SKU",
    },
  },
  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore,
    }
  },
  methods: {
    searchSuggesteTerm (newSearchTerm) {
      this.searchTerm = newSearchTerm;
      this.triggerSearchAutosuggest();
    },
    triggerSearchAutosuggest () {
      if (this.autosuggestEndpoint) {
        if (this.searchTerm.length > 2) {
          this.hideAutosuggest = false;
          this.globalStore.getSearchAutosuggest(this.autosuggestEndpoint + "/?" + this.paramName + '=', this.searchTerm);
        } else {
          this.globalStore.setSearchHits([]);
          this.globalStore.setSearchSuggestions([]);
        }
      }
    },
  },
};
</script>
