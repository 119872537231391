<template>
  <div class="confirmation_checkbox checkbox-pair">
    <label :key="inputName" class="">
      <input :name="inputName" type="checkbox" value="1" :checked="inputIsChecked" :required="inputIsRequired" />
      <span class="checkmark"></span>
      <span @click="trigger">{{ inputLabel }}</span>
    </label>
    <div ref="output"></div>
  </div>
</template>

<script>
export default {
  name: "confirmation-checkbox",
  props: {
    inputName: {
      type: [String],
      default: ''
    },
    inputLabel: {
      type: [String],
      default: ''
    },
    inputIsChecked: {
      type: [Boolean],
      default: false
    },
    inputIsRequired: {
      type: [Boolean],
      default: false
    },
    modalTitle: {
      type: [String],
      default: "",
    },
    modalText: {
      type: [String],
      default: ''
    },
    modalConfirmButtonText: {
      type: [String],
      default: ''
    },
    hasConfirmButton: {
      type: [Boolean],
      default: false
    },
    confirmMessage: {
      type: [String],
      default: ''
    },
    cancelMessage: {
      type: [String],
      default: ''
    }
  },
  methods: {
    trigger (event) {
      event.preventDefault();

      this.$confirmation.showConfirmation({
        title: this.modalTitle,
        text: this.modalText,
        confirmButtonText: this.modalConfirmButtonText,
        hasConfirmButton: this.hasConfirmButton,
      })
          .then(() => {
            if(this.confirmMessage) {
              this.$refs.output.innerHTML = this.confirmMessage;
              setTimeout(() => this.$refs.output.innerHTML = '', 2000)
            }
          })
          .catch(() => {
            if(this.cancelMessage) {
              this.$refs.output.innerHTML = this.cancelMessage;
              setTimeout(() => this.$refs.output.innerHTML = '', 2000)
            }
          })
    },
  }
};
</script>
