<template>
  <div class="qty_field">
    <Button variant="icon-only"
            label="decrease"
            @click="currentValue--">
      <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 13.905H5a1 1 0 1 1 0-2h14a1 1 0 1 1 0 2" fill="currentColor" fill-rule="evenodd"/>
      </svg>
    </Button>
    <label class="label" :for="name">{{ label }}</label>
    <input class="qty" :name="name" :size="size" type="number" v-model="currentValue" />
    <Button variant="icon-only"
            label="increase"
            @click="currentValue++">
      <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 7.905H9v-6a1 1 0 1 0-2 0v6H1a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2" fill="currentColor" fill-rule="evenodd"/>
      </svg>
    </Button>
  </div>
</template>

<script>
import Button from "@/components/molecules/forms/button/Button";
export default {
  name: "qty-field",
  components: {
    Button
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  props: {
    value: {
      type: [Number, String],
      default: 1,
    },
    name: {
      type: [String],
      default: "",
    },
    label: {
      type: [String],
      default: "",
    },
    size: {
      type: [Number, String],
      default: 2,
    },
    type: {
      type: [String],
      default: "number",
    },
  },
};
</script>
