<template>
  <li class="nav-item">
    <a class="nav-link" :href="href" :title="title">{{ content }}</a>
    <template v-if="children && children.length > 0">
      <div class="subnav_wrapper">
        <ul>
          <nav-item v-for="(link, index) in children"
                    :key="index" :href="link.href"
                    :title="link.title"
                    :content="link.content"
                    :children="link.children" />
        </ul>
      </div>
    </template>
  </li>
</template>

<script>
export default {
  name: "nav-item",
  props: {
    href: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    children: {
      type: [Array, String],
      default: function () {
        return [];
      }
    },
  }
};
</script>
