<template>
  <div class="collapsible" :class="'collapsible-' + activeViewMode + '-view'">
    <!-- Items-Navi -->
    <!-- Todo: Use maybe https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details?retiredLocale=de#see_also -->
    <div v-if="activeViewMode === 'tabs'" class="tab_navigation">
      <Button v-for="(item, index) in items" :key="index"
              variant="tab"
              :status="getActiveItem === index ? 'active' : ''"
              :disabled="item.disabled"
              @click="activeIndex = index"
              :label="item.title" />
    </div>
    <!-- Item-Contents -->
    <div class="items-content-wrapper">
      <template v-for="(item, index) in items" :key="index">
        <div>
          <h4 v-if="this.globalStore.isDesktopView && activeViewMode === 'grid'" class="headline">
            {{ item.title }}
          </h4>
          <Button v-if="activeViewMode === 'accordion'"
                  variant="accordion"
                  :status="getActiveItem === index ? 'active' : ''"
                  :disabled="item.disabled"
                  @click="activeIndex = index"
                  :label="item.title" />
          <div class="content"
               :class="getActiveItem === index ? '' : 'sr-only'">
            <div v-html="item.content"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Button from '../forms/button/Button';
import { useGlobalStore } from '@/stores/global'
import { ref, computed, onUnmounted } from 'vue';

export default {
  name: 'collapsible',
  components: {
    Button
  },
  props: {
    activeItem: {
      type: [Number, String],
      default: 0
    },
    items: {
      type: [Array, String],
      default: function () {
        /* @TODO: Switch to TypeScript and describe the shape
           of the expected objects in the array

          export interface Item {
            title: string;
            content: string;
            disabled?: boolean;
          }
        */
        return [
        ]
      }
    },
    viewMode: { /* possible options are: tabs, grid, accordion */
      type: String,
      default: "tabs"
    }
  },
  setup(props) {
    const globalStore = useGlobalStore();
    const activeIndex = ref(props.activeItem);
    const activeViewMode = ref(!globalStore.isDesktopView ? 'accordion' : props.viewMode);

    const getActiveItem = computed(() => {
      let actItem = activeIndex.value;
      if (actItem >= props.items.length) {
        actItem = props.items.length - 1;
      }
      if (actItem < 0 || actItem === '') {
        actItem = 0;
      }
      return actItem;
    });

    /* TODO: ATTENTION: Setting this event Listener inside this component is not best practise. It would be better,
        to handle this globally. */
    let resizeCallback = () => {
      setActiveViewMode();
    };
    window.addEventListener('resize', resizeCallback);
    onUnmounted(() => window.removeEventListener('resize', resizeCallback));
    function setActiveViewMode() {
      globalStore.setIsDesktopView();
      activeViewMode.value = !globalStore.isDesktopView ? 'accordion' : props.viewMode;
    }

    return {
      globalStore,
      activeIndex,
      activeViewMode,
      getActiveItem
    }
  }
};
</script>
