<template>
  <template v-for="(option, optionKey) in possibleOptions" :key="optionKey">
    <div class="option form_row">
      <base-select
          :label="option.label"
          :values="option.values"
          :option="option"
          :elementId="'select_' + option.attribute_code"
          :name="'config_option[' + optionKey + ']'"
          :hint-text-value-was-changed="labelValueWasChangedHint"
          @valueChanged="valueSelected"/>
    </div>
  </template>
</template>

<script>
import { ref } from "vue";
import { useGlobalStore } from '@/stores/global'
import baseSelect from '@/components/molecules/forms/base-select/BaseSelect';

export default {
  name: 'product-attribute-select',
  components: {
    baseSelect,
  },
  props: {
    options: {
      type: [Array, String],
      default: function () {
        return []
      }
    },
    children: {
      type: [Array, String],
      default: function () {
        return []
      }
    },
    name: {
      type: String,
      default: ""
    },
    productSku: {
      type: String,
      default: ""
    },
    labelValueWasChangedHint: {
      type: String,
      default: "Value was changed."
    }
    /* "Alternative-Configurable-Options" HINT. See explanation in ProductController.
    getOptionsEndpoint: {
      type: [String, Object],
      default: null,
    },*/
  },
  setup(props) {
    const globalStore = useGlobalStore();
    const possibleOptions = ref({});
    const selectedValues = ref([]);
    let availableProducts = [];

    async function handleSimpleSelected () {
      // Evaluate the matching simple. Check the selected value and look through all availableProducts which one
      // matches all:
      let numValuesToMatch = Object.keys(selectedValues.value).length;
      // Check which simple product was selected now:
      let selectedSimple = availableProducts.filter(product => {
        let numMatches = 0;
        for (const [key, value] of Object.entries(selectedValues.value)) {
          if (product[key].toString() === value.toString()) {
            numMatches++;
          }
        }
        return numMatches === numValuesToMatch;
      });

      if (selectedSimple.length === 1) {
        console.log("Selected Simple SKU: ", selectedSimple[0].sku);
        // NOTE: This makes only sense for SAP Products!
        //sapStore.setCurrentProductSku(selectedSimple[0].sku);
      }

      // NOTE: This makes only sense for SAP Products!
      //globalLoader.showLoader({ show: true });
      //await sapStore.getPrice();
      //globalLoader.showLoader({ show: false });
    }

    init();

    function init () {
      props.options.forEach(option => {
        // set all first values as initially selected
        selectedValues.value[option.attribute_code] = option.values[0].value_index;

        // set selectedConfigOption Object in store so that other components (for example the image gallery) can show
        // the correct contents (correct product image for example)
        globalStore.addSelectedConfigOption(option.attribute_code, option.values[0].value_index);
      });

      updateAllowedAttributeOptions(props.options[0].attribute_code);

      handleSimpleSelected();
    }

    function updateAllowedAttributeOptions(updatedOptionCode) {
      calculateAllowedAttributeOptions(selectedValues.value, updatedOptionCode);
    }

    function calculateAllowedAttributeOptions(selectedValues, updatedOptionCode) {
      const allOptions = JSON.parse(JSON.stringify(props.options));
      allOptions.sort((a,b) => {
        return a.attribute_code === updatedOptionCode ? -1 : b === updatedOptionCode ? 1 : 0;
      });

      availableProducts = JSON.parse(JSON.stringify(props.children));

      let previousOption = false;

      allOptions.forEach(attribute => {
        if (previousOption && selectedValues[previousOption]) {
          availableProducts = availableProducts.filter(product => {
            return product[previousOption].toString() ===
                selectedValues[previousOption].toString()
          });
        }
        let valuesOf = getNewAllowedAttributesOptionsForAttributeId(attribute);
        possibleOptions.value[attribute.attribute_code] = getOptionWithPossibleValues(attribute, valuesOf);
        previousOption = attribute.attribute_code;
      });
    }

    function getNewAllowedAttributesOptionsForAttributeId (option) {
      let allowedValues = [];
      option.values.forEach(value => {
        availableProducts.forEach(product => {
          if (product[option.attribute_code].toString() === value.value_index) {
            const alreadyInArray = allowedValues.some(item => {
              return item.value_index === value.value_index;
            });
            if (!alreadyInArray) {
              allowedValues.push(value);
            }
          }
        })
      });
      return allowedValues;
    }

    function getOptionWithPossibleValues (option, possibleValues) {
      // make a copy of the option because we have to loose the reactivity. Otherwise the original props.options object
      // will be changed and some options will be deleted:
      let copyOfOption = Object.assign({}, option);
      copyOfOption['values'] = possibleValues;
      return copyOfOption;
    }

    async function valueSelected ( payload ) {
      // updated selectedValues Array with new selected item:
      selectedValues.value[payload.option.attribute_code] = payload.value;

      // updated selectedConfigOption Object in store so that other components (for example the image gallery) can
      // react to the change:
      globalStore.addSelectedConfigOption(payload.option.attribute_code, payload.value);

      // updated possibleOptions and start with selected object (and use fresh selectedValues Array):
      updateAllowedAttributeOptions(payload.option.attribute_code);

      // Check selected simple and handle the selection, for example new Price calculation:
      await handleSimpleSelected();
    }

    return {
    globalStore,
    possibleOptions,
    valueSelected
    }
  }
};
</script>
