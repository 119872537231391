/* eslint-disable no-unused-vars */
import { defineAsyncComponent } from "vue";

// Global registered Components
import ConfirmationCheckbox from "@/components/molecules/forms/confirmation-checkbox";
import SearchFieldAutosuggest from "@/components/layout/header/search-field-autosuggest";
import QtyField from "@/components/molecules/forms/qty-field";
import ConfigurableGalleryHandler from "@/components/catalog/configurable-gallery-handler";
import Collapsible from "@/components/molecules/collapsible";
import ScrollSlider from "@/components/molecules/scroll-slider";
import ProductAttributeSelect from "@/components/catalog/product-attribute-select";
import baseImageGallery from "@/components/molecules/base-image-gallery";
import NavigationBar from "@/components/layout/header/navigation-bar";
import StoreConfigSwitcher from "@/components/layout/header/store-config-switcher";

export default {
  install(app) {
    const components = [
      // SSR Rendered components
      ScrollSlider,
      SearchFieldAutosuggest,
      QtyField,
      ConfigurableGalleryHandler,
      NavigationBar,
      Collapsible,
      ProductAttributeSelect,
      baseImageGallery,
      StoreConfigSwitcher,
      ConfirmationCheckbox
    ];

    components.forEach((component) => {
      app.component(component.name, component);
    });

    // @TODO: Add check for async components to add `defineAsyncComponent`
    // Async Components
  },
};
