<template>
  <a v-if="isLink"
     :href="href"
     :class="`button ${variant} ${status}`"
     :title="label">
    <slot name="before"></slot>
    <span class="label"
          :class="variant.includes('icon-only') ? 'sr-only' : ''">
      {{ label }}
    </span>
    <slot name="default"></slot>
  </a>
  <button v-else :class="`button ${variant} ${status}`"
          :disabled="disabled"
          :type="type" :title="label">
    <slot name="before"></slot>
    <span class="label"
          :class="variant.includes('icon-only') ? 'sr-only' : ''">
      {{ label }}
    </span>
    <span v-if="variant === 'accordion'" class="arrow">
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>{{ label }}</title>
          <path
              d="M15 12.5a.83.83 0 0 1-.534-.193L9.99 8.577l-4.468 3.595a.832.832 0 1 1-1.044-1.298l5-4.023a.832.832 0 0 1 1.056.009l5 4.167A.834.834 0 0 1 15 12.5"
              fill="currentColor" fill-rule="evenodd"/>
        </svg>
    </span>
    <slot name="default"></slot>
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    variant: {
      type: [String],
      default: "secondary",
      validator: (value) =>
          [
            "secondary",
            "primary",
            "secondary outline",
            "primary outline",
            "tab",
            "accordion",
            "primary-dark",
            "icon-only",
            "icon-only primary",
            "with-icon",
            "icon_wrapper",
          ].includes(value),
      required: true,
    },
    status: {
      // @TODO: Can we validate optional props?
      // https://github.com/vuejs/vetur/issues/3084
      // active
      type: [String],
      default: "",
    },
    disabled: {
      type: [Boolean],
      default: false,
    },
    label: {
      type: [String],
      default: "",
      required: true,
    },
    type: {
      type: [String],
      default: "button",
    },
    isLink: {
      type: [Boolean],
      default: false,
    },
    href: {
      type: [String],
      default: '',
    },
  }
};
</script>
