<template>
  <base-image-gallery :images="activeGallery" :isImgNav="true"/>
</template>
<script>
import { useGlobalStore } from '@/stores/global'
import baseImageGallery from '@/components/molecules/base-image-gallery/BaseImageGallery';

export default {
  name: 'configurable-gallery-handler',
  components: {
    baseImageGallery
  },
  props: {
    simpleItems: {
      type: [Array, String],
      default: () => ([])
    }
  },
  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore,
    }
  },
  computed: {
    /*
     * Return only the gallery of the currently selected simple. For this, every configurable attribute has to match to
     * the currently selected Values saved in the globalStore. This is for example saved by the component
     * ProductAttributeSelect.
    */
    activeGallery() {
      let activeProductImages = [];
      this.simpleItems.forEach(simpleItem => {
        /* The configurable attributes are the keys of each object of the simpleItem. Except the key "gallery" - this
        is an extra added property. So we have to ignore this here and the numOfProps is reduced by 1. */
        let numOfProps = Object.keys(simpleItem).length - 1;
        let matchingNumber = 0;
        for (const [key, value] of Object.entries(simpleItem)) {
          /* ignore property "gallery" - reason described above. */
          if (key !== 'gallery') {
            if (this.globalStore.currentSelectedConfigOptions[key]) {
              if (this.globalStore.currentSelectedConfigOptions[key].toString() === value.toString()) {
                matchingNumber++;
              }
            } else {
              // if this config is not set yet, then select here the first one, so that something is selected and shown.
              this.globalStore.addSelectedConfigOption(key, value);
              matchingNumber++;
            }
          }
          if (matchingNumber === numOfProps) {
            activeProductImages = simpleItem.gallery;
          }
        }
      });
      return activeProductImages;
    }
  }
};
</script>
