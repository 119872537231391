<template>
  <div class="navigation-bar">
    <button class="icon_wrapper navbar-toggler"
            @click="opened = !opened"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" :aria-expanded="opened" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="container collapse navbar-collapse"
         :class="opened ? 'opened' : ''"
         id="navbarSupportedContent">
      <ul class="navbar-nav">
        <nav-item v-for="(link, index) in navItems"
                  :key="index" :href="link.href"
                  :title="link.title"
                  :content="link.content"
                  :children="link.children" />
      </ul>
      <div class="navbar-slot">
        <slot></slot>
      </div>
      <button class="icon_wrapper close_navbar"
              v-show="opened"
              @click="opened = !opened"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" :aria-expanded="opened" aria-label="Toggle navigation">
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="m8.943 8 2.862-2.862a.666.666 0 1 0-.943-.943L8 7.057 5.138 4.195a.666.666 0 1 0-.943.943L7.057 8l-2.862 2.862a.666.666 0 1 0 .943.943L8 8.943l2.862 2.862a.665.665 0 0 0 .943 0 .666.666 0 0 0 0-.943L8.943 8z" fill="currentColor" fill-rule="evenodd"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import navItem from '@/components/layout/header/navigation-bar/NavItem';

export default {
  name: "navigation-bar",
  components: {
    navItem,
  },
  data() {
    return {
      opened: false,
    };
  },
  props: {
    navItems: {
      type: [Array, String],
      default: function () {
        return [];
      }
    },
  }
};
</script>
