<template>
  <label class="label" :for="name">
    {{ label }}
  </label>
  <select :name="name"
          required="required"
          :key="selectedItem"
          v-model="selectedItem"
          @change="handleUserChangedValue"
          :id="elementId">
    <!--option value="">Bitte wählen</option-->
    <option
        v-for="(value, index) in values"
        :key="index"
        :disabled="value.disabled ? value.disabled : false"
        :value="value.value_index"
    >
      {{ value.label }}
    </option>
  </select>
  <div v-if="fieldHints.length > 0" class="field_hints warnings">
    <span v-for="(hint, index) in fieldHints"
          :key="index">
      {{ hint }}
    </span>
  </div>
</template>

<script>
/*
 * For further development see:
 * https://git.netz98.de/belvini/vue-storefront/-/blob/develop/pwa/src/themes/belvini/components/atomic/BaseSelect.vue
*/
export default {
  name: 'base-select',
  data() {
    return {
      selectedItem: '',
      referenceValue: '',
      fieldHints: []
    };
  },
  props: {
    values: {
      type: [Array, String, Object],
      default: ''
    },
    hintTextValueWasChanged: {
      type: [String],
      default: 'The value was changed.'
    },
    /* "selectedItem" not used at the moment!
    selectedItem: {
      type: [String],
      default: null
    },
    * */
    label: {
      type: [String],
      default: ''
    },
    name: {
      type: [String],
      default: ''
    },
    elementId: {
      type: [String],
      default: ''
    },
    // in some cases the option is needed as payload in the callback. If your component does not need it, than just
    // leave it empty. But see the payload of the valueChanged event below! So you get the value with payload.value.
    option: {
      type: [Array, Object, String],
      default: ''
    }
  },
  mounted() {
    const valuesArray = Object.values(this.values);
    valuesArray.forEach(value => {
      if (value.selected && this.selectedItem === '') {
        this.referenceValue = value.value_index;
        this.selectedItem = value.value_index;
      }
    });
    if (this.selectedItem === '' && valuesArray.length > 0) {
      if (valuesArray[0]) {
        this.referenceValue = valuesArray[0].value_index;
        this.selectedItem = valuesArray[0].value_index;
      }
    }
  },
  methods: {
    handleUserChangedValue() {
      // This function is ONLY called, if the value was changed by the user and not programmatically. We use this
      // "bug/behaviour" here for removing the fieldHints in that moment. Otherwise the hint would be shown if the
      // value was changed from outside (for example if the user configures something and changes another select
      // field and the values of this are changed because of combination of product attributes) and immediately
      // hidden again.
      this.fieldHints = [];
    }
  },
  emits: ["valueChanged"],
  watch: {
    selectedItem(newValue) {
      // Check if value was changed or it was just actualized by "values-changed" but value is the same asa before:
      if (newValue !== this.referenceValue) {
        // If value was changed, send event and actualize referenceValue for comparison:
        this.referenceValue = newValue;
        this.$emit('valueChanged', {
          value: this.selectedItem,
          option: this.option
        });
      }
    },
    values(newValues, oldValues) {
      let firstValueOfValues;
      // Check if the new values object has a different number of values, so it was filtered by configuration for
      // example. NOTE!! Maybe it would be better to check if it is the same at all - instead of checking only the
      // number of options. This case here makes only sense for filtering by configuration - but what if number of
      // values is the same but different once? Is there a case for this?
      if (Object.keys(newValues).length !== Object.keys(oldValues).length) {
        firstValueOfValues = newValues[0].value_index;
        if (!Object.values(newValues).find(el => el.value_index === this.selectedItem)) {
          // current selected Value does not exist anymore in the values object, so select the first one instead:
          //this.referenceValue = firstValueOfValues;
          this.fieldHints.push(this.hintTextValueWasChanged);
          this.selectedItem = firstValueOfValues;
        }
      }
    }
  }
};
</script>
