<template>
  <div :class="[{'has-img-nav': isImgNav}, 'image-gallery']">
    <ul class="gallery-wrapper">
      <li v-for="(image, index) in images" :key="image.imgPos" v-show="index === activeItem" class="gallery-item">
        <img class="main_image" :src="image.imgUrl" width="600" height="600"  alt=""/>
      </li>
    </ul>
    <ol v-if="images.length > 1 && !isImgNav" class="gallery-nav dot-nav">
      <li v-for="(image, index) in images"
          :key="image.imgPos"
          @click="activeIndex = index"
          :class="activeItem === index ? 'active' : ''">
        <span>Show Image Number {{ index + 1 }}</span>
      </li>
    </ol>
    <ul class="gallery-nav is-img-nav" v-else-if="images.length > 1 || isImgNav">
      <li v-for="(image, index) in images"
          :class="activeItem === index ? 'active' : ''"
          :key="image.imgPos">
        <img @click="activeIndex = index"
             :src="image.imgUrl"  alt="Thumbnail"/>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'base-image-gallery',
  data() {
    return {
      activeIndex: this.activeImage,
    };
  },
  props: {
    activeImage: {
      type: [Number, String],
      default: 0
    },
    images: {
      type: [Array, String],
      default: () => {
        return [
          {
            imgUrl: "https://picsum.photos/420/320?image=0",
            imgPos: 0
          },
          {
            imgUrl: "https://picsum.photos/420/320?image=1",
            imgPos: 1
          },
          {
            imgUrl: "https://picsum.photos/420/320?image=2",
            imgPos: 2
          }
        ]
      }
    },
    isImgNav: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeItem: function () {
      let actImage = this.activeIndex;
      if (actImage >= this.images.length) {
        actImage = this.images.length - 1;
      }
      if (actImage < 0 || actImage === '') {
        actImage = 0;
      }
      return actImage;
    }
  }
};
</script>

<style lang="scss">
@import "../../../assets/css/utilities/_variables";

.gallery-wrapper {
  overflow: hidden;
  background: $gallery-thumbs__bg;
  border-radius: $gallery-thumbs__border-radius;
  padding: $spacer-lg;
}

.gallery-item {
  background-color: $white;

  &.shown {
    display: block;
  }

  &.hidden {
    display: none;
  }
}

.main_image {
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.gallery-nav {
  display: flex;
  justify-content: center;
  padding: $spacer-sm;

  li {
    margin: 0 $spacer-sm;
    cursor: pointer;
  }

  &.dot-nav {
    li {
      &:before {
        content: '';
        display: inline-block;
        border-radius: 8px;
        width: 16px;
        height: 16px;
        background-color: $link-color;
      }

      &.active {
        &:before {
          background-color: $link-color__hover;
        }
      }
    }
    span {
      display: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.is-img-nav {
    flex-wrap: wrap;
    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: $gallery-thumbs__border-radius;
      background: $gallery-thumbs__bg;
      padding: $spacer-sm;
      width: $gallery-thumbs__width;
      height: $gallery-thumbs__height;
      &.active {
        &:after {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          content: '';
          background: $gallery-thumbs__bg__active;
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    img {
      cursor: pointer;
      width: 100%;
    }
  }
}

</style>
