<template>
  <div v-if="isOpen" class="confirmation_modal">
    <div class="modal_inner">
      <h3>{{ title }}</h3>
      <div v-html="text"></div>
      <div v-if="hasConfirmButton" class="action_wrapper">
        <button class="primary" v-if="hasConfirmButton" @click="onConfirm">{{ confirmButtonText }}</button>
      </div>
      <span class="cancel" @click="onCancel">
          <svg width="40" height="40" viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg">
            <path d="m8.943 8 2.862-2.862a.666.666 0 1 0-.943-.943L8 7.057 5.138 4.195a.666.666 0 1 0-.943.943L7.057 8l-2.862 2.862a.666.666 0 1 0 .943.943L8 8.943l2.862 2.862a.665.665 0 0 0 .943 0 .666.666 0 0 0 0-.943L8.943 8z" fill="currentColor" fill-rule="evenodd"/>
          </svg>
        </span>
    </div>
  </div>
</template>

<script>
  /**
   * Confirmation modal handling.
   * Can get called by this.$confirmation.showConfirmation() and resolves a Promise if it's confirmed.
   */
  export default {
    name: 'confirmation-modal',
    // components: {},
    // mixins: [],

    // props: {},
    data() {
      return {
        /**
         * @type {Boolean} Flag defines if the modal is currently open.
         */
        isOpen: false,

        /**
         * @type {String} Defines the title of the modal.
         */
        title: '',

        /**
         * @type {String} Defines the content text of the modal.
         */
        text: '',

        /**
         * @type {String} Defines confirmation button text.
         */
        confirmButtonText: '',

        /**
         * @type {Boolean} Defines if the confirmation can be confirmed.
         */
        hasConfirmButton: true,
      };
    },

    // computed: {},
    watch: {
      /**
       * Observes "confirmation" attribute in the $confirmation plugin.
       * Set's the data for the modal and opens the modal if it's filled correctly.
       *
       * @param {Object} value - The confirmation object with it's payload.
       */
      '$confirmation.confirmation': function(value) {
        const {
          payload,
        } = value || {};

        if (payload) {
          this.setData(payload);
        }

        this.isOpen = !!payload;
      }
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeDestroy() {},
    // destroyed() {},

    methods: {
      /**
       * Sets the data for the modal.
       *
       * @param {Object} options - The confirmation modal instance data.
       * @param {String} options.title - The confirmation modal title.
       * @param {String} [options.text] - The confirmation modal content text.
       * @param {String} options.confirmButtonText - The label for the confirm button.
       * @param {Boolean} [options.hasConfirmButton = true] - Defines if the modal has a confirm button.
       */
      setData(options = {}) {
        const {
          title,
          text,
          confirmButtonText,
          hasConfirmButton,
        } = options;

        this.title = title;
        this.text = text;
        this.confirmButtonText = confirmButtonText;
        this.hasConfirmButton = hasConfirmButton !== false;
      },

      /**
       * Confirmation event handler.
       */
      onConfirm() {
        if (this.$confirmation.confirmation) {
          this.$confirmation.confirmation.resolve();
        }
      },

      /**
       * Cancel event handler.
       */
      onCancel() {
        if (this.$confirmation.confirmation) {
          this.$confirmation.confirmation.reject();
        }
      },
    },
    // render() {},
  };
</script>

<style lang="scss" scoped>
@import "../../assets/css/utilities/_variables";

.confirmation_modal {
  position: fixed;
  max-width: 100%;


  top: 0;
  bottom: 0;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  color: $text-color;
  background-color: $primary-light;
  border: 1px solid $border-color__base;
  padding: $spacer-xl*2;
  width: 800px;
  min-height: 300px;
  max-height: 80%;
}

.modal_inner {
  overflow-y: scroll;
  height: fit-content;
  min-height: 300px;
  max-height: 95%;
}

.modal_inner::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.modal_inner {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.cancel {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
