import { createApp } from 'vue';
import { createPinia } from 'pinia'
import plugins from '@/setup/plugins';
import components from '@/setup/components';
import '@/assets/css/main.scss';

const app = createApp({})
  .use(createPinia())
  .use(components);

if (Array.isArray(plugins)) {
  plugins.forEach(plugin => app.use(plugin))
}

app.mount('#app');
